<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline"
          >{{ selectedItem ? "Editar" : "Agregar" }} premio</span
        >
        <v-btn
          style="right: 10px; top: 10px"
          icon
          :loading="loading"
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid class="pl-5 pr-5">
          <v-row>
            <v-col
              style="display: flex; justify-content: center"
              cols="12"
              sm="6"
              md="6"
            >
              <img
                @click.prevent="showFileChooser"
                class="image"
                :src="defaultImage"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <p>Nombre del premio</p>
                  <v-text-field
                    outlined
                    :loading="loading"
                    :rules="[rules.required]"
                    rounded
                    placeholder="Ingrese el nombre del premio"
                    v-model="item.name"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <p>Descripción</p>
                  <v-textarea
                    outlined
                    rounded
                    :loading="loading"
                    rows="3"
                    hide-details
                    placeholder="Ingrese la descripción"
                    v-model="item.description"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <p>Titulo</p>
              <v-text-field
                outlined
                :loading="loading"
                :rules="[rules.required]"
                hint="Se muestra cuando el participante gana este premio."
                rounded
                rows="4"
                placeholder="Ingrese el título"
                v-model="item.title"
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <p>Subtítulo</p>
              <v-text-field
                outlined
                rounded
                :loading="loading"
                :rules="[rules.required]"
                hint="Se muestra cuando el participante gana este premio."
                rows="4"
                placeholder="Ingrese el subtítulo"
                v-model="item.subTitle"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <p>Color de fondo</p>
              <input
                outlined
                rounded
                :loading="loading"
                :rules="[rules.required]"
                class="control-input color"
                type="color"
                placeholder="Seleccione el color de fondo"
                v-model="item.backgroundColor"
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <p>Inventario</p>
              <v-text-field
                outlined
                rounded
                :loading="loading"
                :rules="[rules.required]"
                placeholder="Ingrese la cantidad de premios disponibles"
                v-model.number="item.inventory"
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <p>Tipo de premio</p>
              <v-select
                :rules="[rules.required]"
                outlined
                :loading="loading"
                :items="types"
                v-model="item.type"
              />
            </v-col>

            <v-col v-if="item.type == 'coupon'" cols="12" sm="6" md="6">
              <p>Código del cupón</p>
              <v-text-field
                outlined
                :rules="[rules.required]"
                rounded
                rows="4"
                :loading="loading"
                placeholder="Ingrese el código del cupón"
                v-model="item.target.coupon"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6" md="6">
              <v-switch
                label="Es premio"
                :loading="loading"
                v-model="item.winner"
              />
            </v-col>

            <v-col cols="6" sm="6" md="6">
              <v-switch
                label="Manejar inventario"
                :loading="loading"
                v-model="item.manageInventory"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-container fluid class="pl-5 pr-5">
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="start">
                <v-switch
                  v-model="item.active"
                  :loading="loading"
                  label="Activo"
                ></v-switch>
              </v-row>
            </v-col>

            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="end">
                <v-btn
                  @click="selectedItem ? update() : save()"
                  :loading="loading"
                  class="save-btn mt-4"
                  color="primary"
                  >Guardar</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>

    <input
      class="upload"
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="imageChanged"
    />
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapState } from "vuex";

export default {
  name: "add-prize",
  props: ["selectedItem", "event"],
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      loading: true,
      defaultImage: require("@/assets/product-default-01.jpg"),
      file: null,
      rules: {
        required: (value) => !!value || "Obligatorio",
      },
      item: {
        active: true,
        target: {},
        backgroundColor: "#ad2525",
        inventory: 0,
      },

      types: [
        { text: "Cupón", value: "coupon" },
        { text: "Artículos promocionales ", value: "promotional" },
        { text: "Nuevo intento", value: "again" },
        { text: "Sin premio", value: "none" },
      ],
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    showFileChooser() {
      this.$refs.input.click();
    },
    async update() {
      try {
        let validate = await this.validation();

        this.loading = true;

        let data = {
          name: this.item.name,
          description: this.item.description ? this.item.description : "",
          active: this.item.active,
          title: this.item.title,
          modifiedAt: new Date(),
          modifiedBy: this.user[".key"],
          backgroundColor: this.item.backgroundColor,
          deleted: false,
          subTitle: this.item.subTitle,
          type: this.item.type,
          inventory: this.item.inventory ? this.item.inventory : 0,
          manageInventory: this.item.manageInventory
            ? this.item.manageInventory
            : false,
          winner: this.item.winner || false,
          target: {
            coupon:
              this.item.target && this.item.target.coupon
                ? this.item.target.coupon
                : "",
          },
        };

        db.collection("roulette")
          .doc(this.event[".key"])
          .collection("prizes")
          .doc(this.selectedItem[".key"])
          .update(data)
          .then(async (response) => {
            if (this.file) {
              await this.uploadFile(this.selectedItem[".key"]);
            }
            this.loading = false;
            this.$emit("success");
          })
          .catch((err) => {
            // console.log(err);
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            this.loading = false;
          });
      } catch (error) {
        this.snackbarText = error;
        this.snackbar = true;
      }
    },
    async save() {
      try {
        let validate = await this.validation();

        this.loading = true;

        let data = {
          name: this.item.name,
          description: this.item.description ? this.item.description : "",
          active: this.item.active,
          title: this.item.title,
          createdAt: new Date(),
          createdBy: this.user[".key"],
          backgroundColor: this.item.backgroundColor,
          deleted: false,
          subTitle: this.item.subTitle,
          type: this.item.type,
          inventory: this.item.inventory ? this.item.inventory : 0,
          manageInventory: this.item.manageInventory
            ? this.item.manageInventory
            : false,
          winner: this.item.winner || false,
          target: {
            coupon:
              this.item.target && this.item.target.coupon
                ? this.item.target.coupon
                : "",
          },
        };

        db.collection("roulette")
          .doc(this.event[".key"])
          .collection("prizes")
          .add(data)
          .then(async (ref) => {
            if (this.file) {
              await this.uploadFile(ref.id);
            }
            this.loading = false;
            this.$emit("success");
          })
          .catch((err) => {
            console.log(err);
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            this.loading = false;
          });
      } catch (error) {
        this.snackbarText = error;
        this.snackbar = true;
      }
    },

    uploadFile(docId) {
      return new Promise((resolve, reject) => {
        let ext = this.file.type.split("/")[1];
        fb.app()
          .storage(`gs://${process.env.VUE_APP_PROJECT_ID}-roulette`)
          .ref()
          .child(`${this.event[".key"]}/${docId}/fie.${ext}`)
          .put(this.file)
          .then(() => {
            return resolve("success");
          })
          .catch((err) => {
            console.log(err);
            return reject(err);
          });
      });
    },

    onEnter(id) {
      this.item.terms.splice(id + 1, 0, "");
      setTimeout(() => {
        this.$refs[`term-${id + 1}`][0].focus();
      }, 100);
    },

    remove(id) {
      if (this.item.terms.length > 1) {
        this.item.terms.splice(id, 1);
      } else {
        this.item.terms = [""];
      }
    },
    validation() {
      return new Promise(async (resolve, reject) => {
        if (!this.item.name) {
          return reject("Debe ingresar el nombre del premio.");
        }

        if (!this.item.subTitle) {
          return reject("Debe ingresar el subtítulo del premio.");
        }

        if (!this.item.title) {
          return reject("Debe ingresar el título del premio.");
        }

        if (!this.item.backgroundColor) {
          return reject("Debe ingresar el color del fondo.");
        }

        if (!this.item.type) {
          return reject("Debe ingresar el tipo de premio.");
        }

        if (this.item.type == "coupon" && !this.item.target.coupon) {
          return reject("Debe ingresar el código del cupón.");
        }

        return resolve("success");
      });
    },

    imageChanged(e) {
      if (!e.target.files[0]) {
        return;
      }

      this.file = e.target.files[0];

      if (this.file && this.file.type.indexOf("image/") === -1) {
        console.log("tipo de archivo no permitido");
        this.file = null;
      }

      var reader = new FileReader();
      reader.onload = (e) => {
        this.defaultImage = e.target.result;
      };

      if (this.file) {
        reader.readAsDataURL(this.file);
      }
    },
  },
  async mounted() {
    this.loading = false;

    if (this.selectedItem) {
      this.item = Object.assign({}, this.selectedItem);
      this.defaultImage = this.item.image
        ? this.item.image.original
        : this.defaultImage;
    }
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  overflow-x: hidden !important;
}

.color {
  height: 50px;
  padding: 0;
  margin-top: 0;
}

.image {
  max-width: 100%;
  max-height: 270px;
  margin-top: 10px;
  object-fit: cover;
}

.upload {
  display: none;
}
</style>