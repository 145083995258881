var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-snackbar',{attrs:{"absolute":"","top":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarText))]),_c('v-card',[_c('v-card-title',{staticClass:"grid-close"},[_c('span',{staticClass:"headline"},[_vm._v("Premios de "+_vm._s(_vm.selectedItem.name))]),_c('v-btn',{staticStyle:{"right":"10px","top":"10px"},attrs:{"icon":"","color":"primary","fab":"","absolute":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fa-times")])],1)],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7","sm":"7"}},[_c('v-text-field',{staticClass:"d-none d-md-flex",attrs:{"clearable":"","rounded":"","flat":"","filled":"","hide-details":"","label":`Buscar premio`,"prepend-inner-icon":"fa-search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"5","sm":"5"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"ma-2 white--text mr-5",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){_vm.sortDialog = true}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"right":"","dark":""}},[_vm._v("fas fa-bars")]),_vm._v(" Ordenar ")],1),_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.addPrize}},[_c('v-icon',{staticClass:"mr-3",attrs:{"right":"","dark":""}},[_vm._v("fas fa-plus")]),_vm._v(" Agregar premio ")],1)],1)],1)],1),_c('v-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.headers,"items":_vm.prizes,"search":_vm.search,"items-per-page":5,"fixed-header":"","loading":_vm.loading,"sort-by":['position'],"sort-desc":[false, true],"item-key":"id","footer-props":{
            itemsPerPageOptions: [10],
          }},scopedSlots:_vm._u([{key:`item.active`,fn:function({ item }){return [_c('v-switch',{attrs:{"loading":_vm.loading},on:{"change":function($event){return _vm.switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  item
                )}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:`item.image`,fn:function({ item }){return [(item.image)?_c('v-avatar',{attrs:{"color":item.backgroundColor}},[_c('img',{attrs:{"src":item.image.original}})]):_vm._e()]}},{key:`item.type`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("filterType")(item.type))+" ")]}},{key:`item.options`,fn:function({ item }){return [_c('v-btn',{attrs:{"loading":_vm.loading,"small":"","color":"primary"},on:{"click":function($event){return _vm.editPrize(item)}}},[_vm._v("Editar")]),_c('v-btn',{staticClass:"ml-5 white--text",attrs:{"loading":_vm.loading,"small":"","color":"red darken-4"},on:{"click":function($event){return _vm.showDelete(item)}}},[_vm._v("Eliminar")])]}},{key:"loading",fn:function(){return [_c('div',{staticClass:"ma-5",staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","z-index":"999","width":"100%","height":"100%","display":"flex","flex-direction":"column","justify-content":"center","align-items":"center"}},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":300,"width":400},on:{"animCreated":_vm.handleAnimation}}),_c('p',{class:_vm.$vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'},[_vm._v(" Cargando Data ")])],1),_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto d-none d-md-block",attrs:{"type":"table-tbody"}})],1)]},proxy:true}],null,true)})],1)],1)],1),(_vm.addDialog)?_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c('Add',{attrs:{"selectedItem":_vm.selectedPrize,"event":_vm.selectedItem},on:{"cancel":function($event){_vm.addDialog = false},"success":_vm.handleSuccess}})],1):_vm._e(),(_vm.deleteDialog)?_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('Delete',{attrs:{"item":_vm.selectedPrize,"loading":_vm.loading},on:{"cancel":function($event){_vm.deleteDialog = false},"confirm":_vm.confirmDeleted}})],1):_vm._e(),(_vm.sortDialog)?_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.sortDialog),callback:function ($$v) {_vm.sortDialog=$$v},expression:"sortDialog"}},[_c('Sort',{attrs:{"items":_vm.prizes,"loading":_vm.loading},on:{"cancel":function($event){_vm.sortDialog = false},"success":_vm.handleSort}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }