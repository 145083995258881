<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">Premios de {{ selectedItem.name }}</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="7" sm="7">
              <v-text-field
                v-model="search"
                clearable
                rounded
                flat
                filled
                hide-details
                :label="`Buscar premio`"
                prepend-inner-icon="fa-search"
                class="d-none d-md-flex"
              />
            </v-col>
            <v-col cols="12" md="5" sm="5">
              <v-row justify="end">
                <v-btn
                  color="primary"
                  class="ma-2 white--text mr-5"
                  @click="sortDialog = true"
                  :loading="loading"
                >
                  <v-icon right dark class="mr-3">fas fa-bars</v-icon>
                  Ordenar
                </v-btn>

                <v-btn
                  color="primary"
                  class="ma-2 white--text"
                  @click="addPrize"
                  :loading="loading"
                >
                  <v-icon right dark class="mr-3">fas fa-plus</v-icon>
                  Agregar premio
                </v-btn>
              </v-row>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="prizes"
            :search="search"
            :items-per-page="5"
            fixed-header
            :loading="loading"
            :sort-by="['position']"
            class="mt-5"
            :sort-desc="[false, true]"
            item-key="id"
            :footer-props="{
              itemsPerPageOptions: [10],
            }"
          >
            <template v-slot:[`item.active`]="{ item }">
              <v-switch
                :loading="loading"
                @change="
                  switchControlChanged(
                    { active: item.active },
                    item['.key'],
                    item
                  )
                "
                v-model="item.active"
              ></v-switch>
            </template>

            <template v-slot:[`item.image`]="{ item }">
              <v-avatar v-if="item.image" :color="item.backgroundColor">
                <img :src="item.image.original" />
              </v-avatar>
            </template>

            <template v-slot:[`item.type`]="{ item }">
              {{ item.type | filterType }}
            </template>

            <template v-slot:[`item.options`]="{ item }">
              <v-btn
                :loading="loading"
                @click="editPrize(item)"
                small
                color="primary"
                >Editar</v-btn
              >
              <v-btn
                class="ml-5 white--text"
                :loading="loading"
                @click="showDelete(item)"
                small
                color="red darken-4"
                >Eliminar</v-btn
              >
            </template>

            <template v-slot:loading>
              <div class="ma-5" style="position: relative">
                <div
                  style="
                    position: absolute;
                    z-index: 999;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <lottie
                    :options="defaultOptions"
                    :height="300"
                    :width="400"
                    v-on:animCreated="handleAnimation"
                  />
                  <p
                    :class="
                      $vuetify.theme.dark
                        ? 'subtitle-1 primary--text'
                        : 'subtitle-1 black--text'
                    "
                  >
                    Cargando Data
                  </p>
                </div>
                <v-skeleton-loader
                  ref="skeleton"
                  type="table-tbody"
                  class="mx-auto d-none d-md-block"
                ></v-skeleton-loader>
              </div>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog persistent v-model="addDialog" v-if="addDialog" max-width="800px">
      <Add
        @cancel="addDialog = false"
        :selectedItem="selectedPrize"
        @success="handleSuccess"
        :event="selectedItem"
      ></Add>
    </v-dialog>

    <v-dialog
      persistent
      v-model="deleteDialog"
      v-if="deleteDialog"
      max-width="500px"
    >
      <Delete
        @cancel="deleteDialog = false"
        :item="selectedPrize"
        :loading="loading"
        @confirm="confirmDeleted"
      ></Delete>
    </v-dialog>

    <v-dialog
      persistent
      v-model="sortDialog"
      v-if="sortDialog"
      max-width="500px"
    >
      <Sort
        @cancel="sortDialog = false"
        :items="prizes"
        :loading="loading"
        @success="handleSort"
      ></Sort>
    </v-dialog>
  </div>
</template>
<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import * as animationData from "@/assets/ochoColor.json";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import Add from "./add-prize";
import Delete from "@/components/delete";
import Sort from "@/components/sort";

import { mapState } from "vuex";

export default {
  name: "prizes",
  props: ["selectedItem"],
  components: {
    lottie: Lottie,
    Add,
    Delete,
    Sort,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      selectedPrize: null,
      snackbar: false,
      snackbarText: "",
      loading: true,
      addDialog: false,
      sortDialog: false,
      search: null,
      prizes: [],
      deleteDialog: false,
      headers: [
        { value: "image" },
        { text: "Posición", value: "position" },
        { text: "Nombre", value: "name" },
        { text: "Inventario", value: "inventory" },
        { text: "Reclamados", value: "claimed" },
        { text: "Tipo", value: "type" },
        { text: "Activo", value: "active" },
        { value: "options", align: "end" },
      ],
    };
  },

  filters: {
    filterType(e) {
      let types = {
        coupon: "Cupón",
        promotional: "Artículos promocionales",
        again: "Nuevo intento",
        none: "Ningún premio",
      };

      return types[e] ? types[e] : "";
    },
  },

  computed: {
    ...mapState(["user"]),
  },

  methods: {
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    async handleSort(data) {
      this.loading = true;
      for (let i = 0; i < data.length; i++) {
        const prize = data[i];
        await db
          .collection(`roulette/${this.selectedItem[".key"]}/prizes`)
          .doc(prize[".key"])
          .update({
            position: i + 1,
          });
      }
      this.loading = false;
      this.sortDialog = false;
    },

    confirmDeleted() {
      this.loading = true;

      db.collection(`roulette/${this.selectedItem[".key"]}/prizes`)
        .doc(this.selectedPrize[".key"])
        .update({
          deleted: true,
          deletedAt: new Date(),
          deletedBy: this.user[".key"],
        })
        .then((response) => {
          this.snackbarText = "Premio eliminado exitosamente.";
          this.snackbar = true;
          this.loading = false;
          this.deleteDialog = false;
        })
        .catch((err) => {
          this.snackbarText = err.message;
          this.snackbar = true;
          this.loading = false;
        });
    },

    showDelete(item) {
      this.selectedPrize = item;
      this.deleteDialog = true;
    },

    addPrize() {
      this.selectedPrize = null;
      this.addDialog = true;
    },

    editPrize(item) {
      this.selectedPrize = item;
      this.addDialog = true;
    },

    handleSuccess() {
      this.snackbarText = `Premio ${
        this.selectedPrize ? "editado" : "agregado"
      } correctamente.`;
      this.snackbar = true;
      this.addDialog = false;
    },

    switchControlChanged(data, prizeId, prize) {
      if (data && prizeId) {
        this.loading = true;
        db.collection(`roulette/${this.selectedItem[".key"]}/prizes`)
          .doc(prizeId)
          .update(data)
          .then((ref) => {
            this.loading = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            prize.active = !prize.active;
          });
      }
    },
  },
  async mounted() {
    await this.$binding(
      "prizes",
      db
        .collection("roulette")
        .doc(this.selectedItem[".key"])
        .collection("prizes")
        .where("deleted", "==", false)
    );

    this.loading = false;
  },
};
</script>
