var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"px-2",attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"absolute":"","top":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarText))]),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("Ruleta")])])],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.newEvent}},[_c('v-icon',{staticClass:"mr-3",attrs:{"right":"","dark":""}},[_vm._v("fas fa-plus")]),_vm._v(" Crear evento ")],1)],1)],1)],1),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.roulette,"items-per-page":10,"loading":_vm.loading,"search":_vm.$store.state.search,"sort-desc":true,"item-key":".key","sort-by":"createdAt","footer-props":{
          itemsPerPageOptions: [10, 25, 50, 100, 250],
        }},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"ma-5",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"inner-lottie"},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":300,"width":400},on:{"animCreated":_vm.handleAnimation}}),_c('p',{class:_vm.$vuetify.theme.dark
                    ? 'subtitle-1 primary--text'
                    : 'subtitle-1 black--text'},[_vm._v(" Cargando Data ")])],1),_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto d-none d-md-block",attrs:{"type":"table-tbody"}})],1)]},proxy:true},{key:`item.code`,fn:function({ item }){return [_c('v-chip',{staticStyle:{"display":"inline-block"},attrs:{"color":_vm.$vuetify.theme.dark
                ? 'rgba(0, 0, 0, 0.4)'
                : 'rgba(0, 0, 0, 0.1)',"dark":"","text-color":_vm.$vuetify.theme.dark ? 'white' : 'black'},on:{"click":function($event){return _vm.copyToClipboard(item.code)}}},[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:`item.active`,fn:function({ item }){return [_c('v-switch',{attrs:{"loading":_vm.loading},on:{"change":function($event){return _vm.switchControlChanged(
                { active: item.active },
                item['.key'],
                item
              )}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:`item.description`,fn:function({ item }){return [_c('div',{staticClass:"no-wrap",staticStyle:{"max-widht":"500px"}},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:`item.options`,fn:function({ item }){return [_c('v-btn',{staticClass:"white--text mr-3",attrs:{"loading":_vm.loading,"small":"","color":"primary"},on:{"click":function($event){return _vm.showPrizes(item)}}},[_vm._v(" Premios ")]),_c('v-btn',{staticClass:"white--text mr-3",attrs:{"small":"","loading":_vm.loading,"color":"primary"},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v(" Editar ")]),_c('v-btn',{staticClass:"white--text",attrs:{"small":"","loading":_vm.loading,"color":"red darken-4"},on:{"click":function($event){return _vm.showDelete(item)}}},[_vm._v(" Eliminar ")])]}}],null,true)})],1)],1),(_vm.addDialog)?_c('v-dialog',{attrs:{"persistent":"","max-width":"700px"},model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c('Add',{attrs:{"selectedItem":_vm.selectedItem},on:{"cancel":function($event){_vm.addDialog = false},"success":_vm.handleSuccess}})],1):_vm._e(),(_vm.prizesDialog)?_c('v-dialog',{attrs:{"persistent":"","max-width":"1200px"},model:{value:(_vm.prizesDialog),callback:function ($$v) {_vm.prizesDialog=$$v},expression:"prizesDialog"}},[_c('Prizes',{attrs:{"selectedItem":_vm.selectedItem},on:{"cancel":function($event){_vm.prizesDialog = false},"success":_vm.handleSuccess}})],1):_vm._e(),(_vm.deleteDialog)?_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('Delete',{attrs:{"item":_vm.selectedItem,"loading":_vm.loading},on:{"cancel":function($event){_vm.deleteDialog = false},"confirm":_vm.confirmDeleted}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }