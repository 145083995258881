<template>
  <v-container fluid class="px-2">
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <h1>Ruleta</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="py-0">
        <v-row justify="end">
          <v-btn
            color="primary"
            class="ma-2 white--text"
            :loading="loading"
            @click="newEvent"
          >
            <v-icon right dark class="mr-3">fas fa-plus</v-icon>
            Crear evento
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="roulette"
          :items-per-page="10"
          :loading="loading"
          :search="$store.state.search"
          :sort-desc="true"
          item-key=".key"
          sort-by="createdAt"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div class="inner-lottie">
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`item.code`]="{ item }">
            <v-chip
              :color="
                $vuetify.theme.dark
                  ? 'rgba(0, 0, 0, 0.4)'
                  : 'rgba(0, 0, 0, 0.1)'
              "
              style="display: inline-block"
              dark
              :text-color="$vuetify.theme.dark ? 'white' : 'black'"
              @click="copyToClipboard(item.code)"
            >
              {{ item.code }}
            </v-chip>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              :loading="loading"
              @change="
                switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  item
                )
              "
              v-model="item.active"
            ></v-switch>
          </template>

          <template v-slot:[`item.description`]="{ item }">
            <div style="max-widht: 500px" class="no-wrap">
              {{ item.description }}
            </div>
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-btn
              :loading="loading"
              small
              @click="showPrizes(item)"
              class="white--text mr-3"
              color="primary"
            >
              Premios
            </v-btn>

            <v-btn
              small
              :loading="loading"
              @click="edit(item)"
              class="white--text mr-3"
              color="primary"
            >
              Editar
            </v-btn>

            <v-btn
              small
              :loading="loading"
              @click="showDelete(item)"
              class="white--text"
              color="red darken-4"
            >
              Eliminar
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog persistent v-model="addDialog" v-if="addDialog" max-width="700px">
      <Add
        @cancel="addDialog = false"
        :selectedItem="selectedItem"
        @success="handleSuccess"
      ></Add>
    </v-dialog>

    <v-dialog
      persistent
      v-model="prizesDialog"
      v-if="prizesDialog"
      max-width="1200px"
    >
      <Prizes
        @cancel="prizesDialog = false"
        :selectedItem="selectedItem"
        @success="handleSuccess"
      ></Prizes>
    </v-dialog>

    <v-dialog
      persistent
      v-model="deleteDialog"
      v-if="deleteDialog"
      max-width="500px"
    >
      <Delete
        @cancel="deleteDialog = false"
        :item="selectedItem"
        :loading="loading"
        @confirm="confirmDeleted"
      ></Delete>
    </v-dialog>
  </v-container>
</template>

<script>
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import { db } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

import Add from "./add";
import Prizes from "./prizes";
import Delete from "@/components/delete";

import { mapState } from "vuex";

export default {
  name: "roulette",
  components: {
    lottie: Lottie,
    Add,
    Prizes,
    Delete,
  },
  mixins: [lottieSettings],
  data() {
    return {
      loading: true,
      snackbar: false,
      snackbarText: "",
      roulette: [],
      addDialog: false,
      deleteDialog: false,
      prizesDialog: false,
      selectedItem: null,
      headers: [
        {
          text: "Nombre",
          value: "name",
          class: "nowrap",
        },

        {
          text: "Código",
          value: "code",
          class: "nowrap",
        },

        {
          text: "Descripción",
          value: "description",
          class: "nowrap",
          width: "500px",
        },

        {
          text: "Activo",
          value: "active",
          class: "nowrap",
        },
        {
          value: "options",
          class: "nowrap",
          align: "end",
          width: "400px",
        },
      ],
    };
  },
  async mounted() {
    this.$store.commit("setSearchTerm", "evento");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    await this.$binding(
      "roulette",
      db.collection("roulette").where("deleted", "==", false)
    );

    this.loading = false;
  },
  computed: {
    ...mapState(["user"]),
  },

  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },

    confirmDeleted() {
      this.loading = true;

      db.collection("roulette")
        .doc(this.selectedItem[".key"])
        .update({
          deleted: true,
          deletedAt: new Date(),
          deletedBy: this.user[".key"],
        })
        .then((response) => {
          this.snackbarText = "Evento eliminado exitosamente.";
          this.snackbar = true;
          this.loading = false;
          this.deleteDialog = false;
        })
        .catch((err) => {
          this.snackbarText = err.message;
          this.snackbar = true;
          this.loading = false;
        });
    },
    switchControlChanged(data, eventId, event) {
      if (data && eventId) {
        this.loading = true;
        db.collection("roulette")
          .doc(eventId)
          .update(data)
          .then(() => {
            this.loading = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch(() => {
            this.loading = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;

            event.active = !event.active;
          });
      }
    },

    showDelete(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    newEvent() {
      this.selectedItem = null;
      this.addDialog = true;
    },
    showPrizes(item) {
      this.selectedItem = item;
      this.prizesDialog = true;
    },
    edit(item) {
      this.selectedItem = item;
      this.addDialog = true;
    },
    handleSuccess() {
      this.snackbarText = "Información actualizada correctamente.";
      this.snackbar = true;
      this.addDialog = false;
    },
    handleModalSummary(collecton, array) {},
  },
  watch: {},
  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY");
    },
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
    filterDuration(miliseconds) {
      return `${Math.round(miliseconds / 86400)} días`;
    },
    filterCouponType(type) {
      switch (type) {
        case "shipping":
          return "Envío";
        case "subtotal":
          return "Subtotal";
        case "total":
          return "Total";
      }
    },
    filterApplyDiscountTo(type) {
      switch (type) {
        case "subtotal":
          return "Subtotal";
        case "total":
          return "Total";
        case "shipping":
          return "Envio";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped>
.inner-lottie {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-wrap {
  max-width: 700px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
